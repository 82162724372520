
import Vue, { PropType } from "vue";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

import { DialogCloseType, DialogI } from "@/types/dialog";

export default Vue.extend({
  name: "DeleteConfirmationDialog",
  components: {
    BaseDialog,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },

  computed: {
    content() {
      return this.dialog.config.payload?.content || "Czy na pewno chcesz usunąć ten obiekt?";
    },
  },
  methods: {
    submitForm() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
      });
    },
  },
});
